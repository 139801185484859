import React from "react"
import Link from "gatsby-link"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Scrollspy from "react-scrollspy-highlight"

import imgMain from "../../../images/wimgMain.png"
import img1 from "../../../images/wimg1.png"
import img2 from "../../../images/wimg2.png"
import img3 from "../../../images/wimg3.png"
import img4 from "../../../images/wimg4.png"

class Intro extends React.Component {
  render() {
    return (
      <section id="intro" className="hero is-fullheight intro">
        <div className="waltzMain">
          <img className="imgMain" src={imgMain} />
          <img className="img1" src={img1} />
          <img className="img2" src={img2} />
          <img className="img3" src={img3} />
          <img className="img4" src={img4} />
        </div>
        <div className="hero-head"></div>
        {/*HERO HEAD END */}
        <div className="hero-body"></div>
        {/*HERO BODY END */}
        <div className="hero-foot">
          <div className="container is-fluid">
            <div className="is-left social-box">
              <p>Follow us</p>
              <span className="zigzag"></span>
              <div className="inner">
                <a target="_blank" href="https://www.facebook.com/waltz.rs/">
                  <FontAwesomeIcon
                    icon={["fab", "facebook-f"]}
                    aria-hidden="true"
                  />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/waltz.rs/?hl=hr"
                >
                  <FontAwesomeIcon
                    icon={["fab", "instagram"]}
                    aria-hidden="true"
                  />
                </a>
              </div>
            </div>
            {/*SOCIAL BOX END */}

            <div className="is-right link-box">
              <Scrollspy items={["proizvodi"]} currentClassName="is-current">
                <Link to="/en/#products">
                  {" "}
                  <FontAwesomeIcon
                    icon={["fas", "arrow-left"]}
                    aria-hidden="true"
                    className="is-desktop"
                  />{" "}
                  Our products{" "}
                  <FontAwesomeIcon
                    icon={["fas", "arrow-right"]}
                    aria-hidden="true"
                    className="is-mobile"
                  />
                </Link>
              </Scrollspy>
            </div>
          </div>
          {/* CONTAINER END */}
        </div>
        {/* HERO FOOTER END */}
      </section> /* SECTION INTRO END */
    )
  }
}

export default Intro
