import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/en/Layout"
import Header from "../../components/en/Header"
import Intro from "../../components/en/homepage/Intro"
import Products from "../../components/en/homepage/Products"
import OurStory from "../../components/en/homepage/OurStory"
import Footer from "../../components/en/Footer"

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <meta charset="utf-8" />
          <title>WALTZ | Welcome</title>
          <meta
            name="description"
            content="Ćatić company DOO, savremeno organizovana i tržišno orjentisana kompanija koja neprestano ulaže u kvalitet i razvoj. Najsavremenija tehnologija, jedinstvena receptura, ispunjavanje visokih svetskih zahteva i standarda za bezbednost hrane je osnova politike Ćatić kompanije."
          />
          <meta name="image" content="https://waltz.rs/waltz_seo_image.jpg" />
          <meta itemprop="name" content="WALTZ | Welcome" />
          <meta
            itemprop="description"
            content="Ćatić company DOO, savremeno organizovana i tržišno orjentisana kompanija koja neprestano ulaže u kvalitet i razvoj. Najsavremenija tehnologija, jedinstvena receptura, ispunjavanje visokih svetskih zahteva i standarda za bezbednost hrane je osnova politike Ćatić kompanije."
          />
          <meta
            itemprop="image"
            content="https://waltz.rs/waltz_seo_image.jpg"
          />
          <meta name="og:title" content="WALTZ | Welcome" />
          <meta
            name="og:description"
            content="Ćatić company DOO, savremeno organizovana i tržišno orjentisana kompanija koja neprestano ulaže u kvalitet i razvoj. Najsavremenija tehnologija, jedinstvena receptura, ispunjavanje visokih svetskih zahteva i standarda za bezbednost hrane je osnova politike Ćatić kompanije."
          />
          <meta
            name="og:image"
            content="https://waltz.rs/waltz_seo_image.jpg"
          />
          <meta name="og:url" content="https://waltz.rs" />
          <meta name="og:site_name" content="WALTZ.rs" />
          <meta name="og:locale" content="sr" />
          <meta name="og:type" content="website" />
        </Helmet>
        <Header />
        <div id="home_page">
          <Intro />
          <Products />
          <OurStory />
          <Footer />
        </div>
      </Layout>
    )
  }
}
export default IndexPage
