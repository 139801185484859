import React from "react"

class OurStory extends React.Component {
  render() {
    return (
      <section id="our_story" className="section our-story">
        <div className="container">
          <div className="title-box">
            <h1 className="title">
              {" "}
              <span className="zigzag"></span> WALTZ STORY{" "}
              <span className="zigzag"></span>
            </h1>
          </div>
          {/*TITLE BOX END */}

          <div className="content">
            <p className="text">
              Ćatić company is modernly organized and market-oriented company
              that constantly invests in quality and development. The basis of
              the Ćatić company's policy are usage of contemporary technology, a
              unique recipe, and meeting the world's highest standards and
              requirements.
            </p>

            <div className="columns">
              <div className="column is-4 padding-0">
                <div className="inner">
                  <span className="icon years"></span>
                  <div className="info">
                    <h2 className="title">33 YEARS of</h2>
                    <h6 className="subtitle">successful business</h6>
                  </div>
                </div>
              </div>

              <div className="column is-4 padding-0">
                <div className="inner">
                  <span className="icon countries"></span>
                  <div className="info">
                    <h2 className="title">10 COUNTRIES</h2>
                    <h6 className="subtitle">Availability of the Product</h6>
                  </div>
                </div>
              </div>

              <div className="column is-4 padding-0">
                <div className="inner">
                  <span className="icon employees"></span>
                  <div className="info">
                    <h2 className="title">130+</h2>
                    <h6 className="subtitle">Employees</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*CONTENT END */}
        </div>
        {/*CONTAINER END */}
      </section> /* SECTION OUR STORY END */
    )
  }
}

export default OurStory
